import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MespickupComponent } from '@features/pickup/mespickup/mespickup.component';
import { ColisofPickupComponent } from '@features/pickup/colisof-pickup/colisof-pickup.component';
import { MsgfourComponent } from '@pages/msgfour/msgfour.component';
import { ColisfourEncoursComponent } from '@features/pickup/colisfour-encours/colisfour-encours.component'
import { NewpasswordComponent } from '@pages/newpassword/newpassword.component';
import { ColissearchboxComponent } from './colissearchbox/colissearchbox.component';
import { ColissearchresultatComponent } from './colissearchresultat/colissearchresultat.component';
import { ColisfourduMoisComponent } from './colisfourdu-mois/colisfourdu-mois.component';
import { CalendarcolisPeriodeComponent } from './calendarcolis-periode/calendarcolis-periode.component';
import { ListecolisPeriodeComponent } from './listecolis-periode/listecolis-periode.component';


@NgModule({
  declarations: [
    MespickupComponent,
    ColisofPickupComponent,
    MsgfourComponent,
    NewpasswordComponent,
    ColisfourEncoursComponent,
    ColissearchboxComponent,
    ColissearchresultatComponent,
    ColisfourduMoisComponent,
    CalendarcolisPeriodeComponent,
    ListecolisPeriodeComponent
   
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class PickupModule { }
