import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Apiendpickup } from '../apiendpickup.enum';

interface DatePeriodeI{
  DateDebut :string|null,
  DateFin:string|null,
  idFour:number
};

interface NotesData {
  note: number,
  idcolis: number,
  idoper: number,
  idfour: number
}

@Component({
  selector: 'app-listecolis-periode',
  templateUrl: './listecolis-periode.component.html',
  styleUrls: ['./listecolis-periode.component.scss'],
})
export class ListecolisPeriodeComponent implements OnInit {
  id!: number;
  endpoint : Apiendpickup = Apiendpickup.getlistecolissurPeriode;
  MakeOperRatingEndpoint :Apiendpickup = Apiendpickup.MakeOperRating;
 
  tableContent:any=[];
  DatePeriode = {} as DatePeriodeI;

  OperNotesData : NotesData = { // permet d'initialiser
    note: 0,
    idcolis: 0,
    idoper: 0,
    idfour: 0
  };

  iconsArray = [1, 2, 3, 4, 5]; // for OperRating purpose
  
  constructor(
    private colisService : Apibase
    ) { }

  ngOnInit() {
    this.id = Number(localStorage.getItem('idFournisseur'));
    this.DatePeriode.DateDebut = localStorage.getItem('DateDebut');
    this.DatePeriode.DateFin = localStorage.getItem('DateFin');
    this.DatePeriode.idFour = this.id;
    //console.log(this.DatePeriode);
     this.colisService.postData(this.endpoint,this.DatePeriode).subscribe(
       (res) => {
         this.tableContent = res;
         //console.log(this.tableContent);
       },
       (error) => {
         console.log(error.message);
        // this.errorHandler.handleError(error);
       },
     
     );
   }

   changeRating(Note,idColis,idOper,idFour){     
        
    if (confirm('Confirmez-Vous la note attribuée à l\'Operateur?')) {
      this.OperNotesData.idcolis = idColis;
      this.OperNotesData.idfour = idFour;
      this.OperNotesData.idoper = idOper;
      this.OperNotesData.note = Note; 
      //console.log('Note oper:',this.OperNotesData); 
      this.colisService.postData(this.MakeOperRatingEndpoint,this.OperNotesData).subscribe(
            (res) => {  
        },
         (error) => {
           //console.log(error);
         },
       );
         // this section is used to reload the data on same page and dynamique update of the UI
              this.colisService.getbyid(this.endpoint,this.id).subscribe(
                (res) => {
                  this.tableContent = res;
                  //console.log(this.tableContent);
                },
                (error) => {
                  //console.log(error);
                 // this.errorHandler.handleError(error);
                },
              
              )

    } else {
      //console.log('No need to rate Me!')
    } 

   } // end changerating

}
