import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import {Router } from '@angular/router';
import { environment } from 'src/environments/environment';


interface Logincredentials {
  Fourtel?: string;
  FourCodePin? : string;
  
}

interface LoginResponse {
  statuscode : number;
  message : string;
  idToken: string;
  expiresIn : number;
  userFourId : number;
  idCommFour :  number;
  ApporteuruserTypeId : string;
  userTypeId: number
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  BaseUrl = environment.AuthUrl;

  constructor(private http:HttpClient,
   private router : Router) {}
 
  
  login(credentials:Logincredentials) {
      return this.http.post<LoginResponse>(
      this.BaseUrl + 'login', credentials)
 
  }
    
  setSession(Logresp:LoginResponse) {
    const expiresAt = moment().add(Logresp.expiresIn,'second');
    localStorage.clear(); //  -Remove all data set into LS first, then set up fresh again - help when Session is expired and user logins back
    localStorage.setItem('idFournisseur', JSON.stringify(Logresp.userFourId));
    localStorage.setItem('idCommFour', JSON.stringify(Logresp.idCommFour));
    localStorage.setItem('ApporteuruserTypeId', JSON.stringify(Logresp.ApporteuruserTypeId));
    //localStorage.setItem('userTypeId', JSON.stringify(Logresp.userTypeId));
    localStorage.setItem('id_token', Logresp.idToken);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()) );
}          

logout() {
  localStorage.clear();/*  -Remove all data set into LS - not implemter ds Mob App  */
  this.router.navigateByUrl('login');
}


isLoggedOut() {
  return !this.isLoggedIn();
}

public isLoggedIn() {
  return moment().isBefore(this.getExpiration());
}

getExpiration() {
  const expiration = localStorage.getItem('expires_at');
  const expiresAt = JSON.parse(expiration || '{}');
  return moment(expiresAt);
}    

}
