import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {DatePipe} from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendarcolis-periode',
  templateUrl: './calendarcolis-periode.component.html',
  styleUrls: ['./calendarcolis-periode.component.scss'],
})
export class CalendarcolisPeriodeComponent implements OnInit {
  CalendarForm: FormGroup;
  PeriodeDate :any =[];
  RelatedCompToDisplay! : string|null;
  DateDebutIn=''; // date start choisi par user
  DateFinIn=''; // date Fin choisi par user

  constructor(
    public fb: FormBuilder,
    private datePipe : DatePipe,
    private router : Router) { 
      this.CalendarForm = this.fb.group({
        DateDebut: ['',[Validators.required]],
        DateFin: ['',[Validators.required]],     
      });
    }


  ngOnInit() {}

  getDateDebut(value){
    this.DateDebutIn = value;
    //console.log(value)
  }

  getDateFin(value){
    this.DateFinIn = value;
    //console.log(value)
  }

  onSubmit() {
     this.RelatedCompToDisplay = 'listecolisSurPeriode'; // PeriodeComp : Comp to show after choice in calendar
     const DateDebut = this.datePipe.transform(this.DateDebutIn,"yyyy-MM-dd");
     const DateFin = this.datePipe.transform(this.DateFinIn,"yyyy-MM-dd");
     this.PeriodeDate.DateDebut = DateDebut;
     this.PeriodeDate.DateFin = DateFin;
     localStorage.setItem('DateDebut',JSON.stringify(DateDebut));
     localStorage.setItem('DateFin',JSON.stringify(DateFin));
     //console.log(this.PeriodeDate)
     this.router.navigate([this.RelatedCompToDisplay])
   
    }





}
