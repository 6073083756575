import { Component, Inject, NgZone, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { Apibase } from '@shared/apibase';
import { Apiendfinance } from '@features/finance/apiendfinance.enum';


@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent {
  id!: number;
  endpoint : Apiendfinance = Apiendfinance.getMobilegraphique1;
  tableContent:any=[];

  private root!: am5.Root;

 constructor(@Inject(PLATFORM_ID) private platformId: Object, private zone: NgZone,
 private dash : Apibase
 ) {}

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit() {
    this.id = Number(localStorage.getItem('idFournisseur'));

    this.dash.getbyid(this.endpoint,this.id).subscribe(
      (res) => {
        this.tableContent = res;
  // Chart code goes in here
  this.browserOnly(() => {
  let root = am5.Root.new("chartdiv");

  root.setThemes([am5themes_Animated.new(root)]);

  let chart = root.container.children.push(
    am5percent.PieChart.new(root, {
      endAngle: 270
    })
  );

  let series = chart.series.push(
    am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "category",
      endAngle: 270
    })
  );
  
  let legend = chart.children.push(am5.Legend.new(root, {}));
  legend.data.setAll(chart.series.values);

  series.states.create("hidden", {
    endAngle: -90
  });
  //console.log(this.tableContent);
  series.data.setAll(this.tableContent);
  
  series.appear(1000, 100);
})
      }, // end subscribe
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      },
    
    );
  }

  ngOnDestroy() {
      // Clean up chart when the component is removed
      this.browserOnly(() => {
        if (this.root) {
          this.root.dispose();
        }
      });
    }



}





