import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Apiendfinance } from '../apiendfinance.enum';

@Component({
  selector: 'app-point-mois',
  templateUrl: './point-mois.component.html',
  styleUrls: ['./point-mois.component.scss'],
})
export class PointMoisComponent implements OnInit {
  id!: number;
  endpoint : Apiendfinance = Apiendfinance.getpointMoisFour;
  tableContent:any=[];

  constructor(
    private finance : Apibase
  ) { }

  ngOnInit() {
   this.id = Number(localStorage.getItem('idFournisseur'));
   
    this.finance.getbyid(this.endpoint,this.id).subscribe(
      (res) => {
        this.tableContent = res;
       // console.log(this.tableContent);
      },
      (error) => {
        console.log(error.message);
       // this.errorHandler.handleError(error);
      },
    
    );
  }

}
