import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
//import { CommonModule } from '@angular/common';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { LoginComponent } from '@pages/login/login.component';
import { PwdoublierComponent } from '@pages/pwdoublier/pwdoublier.component';
import { MenuComponent } from '@pages/menu/menu.component';

import { AppRoutingModule } from './app-routing.module';
import { SyscoreModule } from '@syscore/syscore.module';
import { FeaturesModule } from '@features/features.module';
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment';
import { DatePipe } from '@angular/common';


@NgModule({
  declarations: [
  AppComponent,
  LoginComponent,
  PwdoublierComponent,
  MenuComponent
],
  imports: [
  BrowserModule,
  IonicModule.forRoot(),
  AppRoutingModule,
  //CommonModule,
  ReactiveFormsModule,
  FormsModule,
  SyscoreModule,
  FeaturesModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: environment.production,
    // Register the ServiceWorker as soon as the application is stable
    // or after 30 seconds (whichever comes first).
    registrationStrategy: 'registerWhenStable:30000'
  })
  
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe
  ],
  bootstrap: [AppComponent],
}) 
export class AppModule {}
