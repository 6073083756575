export enum Apiendfinance {
    getpointJourFour ='finance/MobilePointFourJour',
    getpointMoisFour ='finance/MobilePointFourMois',
    getpointSurPeriode ='finance/MobilePointSurPeriode',
    MobilePointJourFourDetailCash='finance/MobilePointJourFourDetailCash',
    MobilePointJourFourDetailMM ='finance/MobilePointJourFourDetailMM',
    MobilePointJourFourDetailCreditLiv='finance/MobilePointJourFourDetailCreditLiv',
    MobilePointJourFourDetailCreditFraisExp='finance/MobilePointJourFourDetailCreditFraisExp',
    getComFour ='finance/getCommDirectFournisseur',
    getDashBoardItems ='dash/getMobileDashBoard',
    getMobilegraphique1 ='dash/getMobilegraphique1'

}
