import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@syscore/service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  binary : boolean = false;
  userorpwdiswrong: boolean = false;
  userdonotexist: boolean = false;
  userTypeID : number = 0;
  constructor(
    private fb:FormBuilder,
    private authservice : AuthService,
    private router : Router
   ) {}
 
 loginForm = this.fb.group({
   Fourtel : ['',[Validators.required,Validators.minLength(3), Validators.maxLength(50)]],
   FourCodePin : ['',[Validators.required, Validators.minLength(3), Validators.maxLength(50)]]
 });
 
 
   ngOnInit(): void {
     this.authservice.logout()
   }
 
   onSubmit(){
     // Do a quick check to see if Form is valid
     
     //this.router.navigateByUrl('main/mespickup');
     if(this.loginForm.invalid) {
            return;
     }
    
     this.authservice.login(this.loginForm.value).subscribe({
         next:  res => {
         
                 if (res['idToken']) {
                   this.authservice.setSession(res);
                  this.router.navigateByUrl('dashboard');
                
                 }else {
 
                         
                  }
         },
         error : err => {

          console.log(err.message);
 
           if(err.status===500) {
             this.loginForm.setErrors({userorpwdiswrong: true});
           
 
           } else {
 
             if(err.status===401)  {
               this.loginForm.setErrors({userdonotexist: true});
               
             }
             else {
               this.loginForm.setErrors({ErreurInconnu: true});
             }
 
           }    
         }
        } )
   }

}
