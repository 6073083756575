import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//import { ApiService } from '@shared/api.service';
import { Apibase } from '@shared/apibase';
import { Apiendpickup } from '../apiendpickup.enum';

@Component({
  selector: 'app-mespickup',
  templateUrl: './mespickup.component.html',
  styleUrls: ['./mespickup.component.scss'],
})
export class MespickupComponent implements OnInit {
  tableContent:any=[]; // Define table content obtained from a service
  id! : number;
  endpoint : Apiendpickup = Apiendpickup.getAllpickupByFour;

  constructor(private picks :Apibase ,
    private router : Router
   ) { }

  ngOnInit() {
    this.id = Number(localStorage.getItem('idFournisseur'));
    
    this.picks.getbyid(this.endpoint,this.id).subscribe(
      (res) => {
        this.tableContent = res;
        //console.log(this.tableContent);
      },
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      },
    
    );
  }

  ListeColis(code){
    //console.log("This is the Code Pickup :", code);
    this.router.navigate(['colisofpickup/'+code]);

  }



}
