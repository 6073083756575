import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Apiendfinance } from '../apiendfinance.enum';

interface DatePeriodeI{
  DateDebut :string|null,
  DateFin:string|null,
  idFour:number
}

@Component({
  selector: 'app-detailcash-periode',
  templateUrl: './detailcash-periode.component.html',
  styleUrls: ['./detailcash-periode.component.scss'],
})
export class DetailcashPeriodeComponent implements OnInit {
  id!: number;
  endpoint : Apiendfinance = Apiendfinance.MobilePointJourFourDetailCash;
  tableContent:any=[];
  DatePeriode = {} as DatePeriodeI;

  constructor(   
    private finance : Apibase
    ) { }

  ngOnInit() {
    this.id = Number(localStorage.getItem('idFournisseur'));
    this.DatePeriode.DateDebut = localStorage.getItem('DateDebut');
    this.DatePeriode.DateFin = localStorage.getItem('DateFin');
    this.DatePeriode.idFour = this.id;
    //console.log(this.DatePeriode);
     this.finance.postData(this.endpoint,this.DatePeriode).subscribe(
       (res) => {
         this.tableContent = res;
         //console.log(this.tableContent);
       },
       (error) => {
         console.log(error.message);
        // this.errorHandler.handleError(error);
       },
     
     );
   }

}
