import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickupModule } from './pickup/pickup.module';
import { FinanceModule } from './finance/finance.module';
import { MarketingModule } from './marketing/marketing.module';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';



@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    PickupModule,
    FinanceModule,
    MarketingModule
  
  ]
})
export class FeaturesModule { }
