import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Apiendpickup } from '@features/pickup/apiendpickup.enum';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';

interface SearchColisResult{
  idFournisseur :number,
  PhoneClient : string,
};

interface NotesData {
  note: number,
  idcolis: number,
  idoper: number,
  idfour: number
}

@Component({
  selector: 'app-colissearchresultat',
  templateUrl: './colissearchresultat.component.html',
  styleUrls: ['./colissearchresultat.component.scss'],
})
export class ColissearchresultatComponent implements OnInit {
 idFour! : number;
 id! : string;
 iconsArray = [1, 2, 3, 4, 5]; // for OperRating purpose
 endpoint : Apiendpickup = Apiendpickup.MobAppSearchColisByClientPhone;
 MakeOperRatingEndpoint :Apiendpickup = Apiendpickup.MakeOperRating;
 tableContent:any=[];
 PhoneFound : boolean = false
 SColis = {} as SearchColisResult;
 
OperNotesData : NotesData = { // permet d'initialiser
  note: 0,
  idcolis: 0,
  idoper: 0,
  idfour: 0
};

  constructor(
    private colisService : Apibase,
    private route : ActivatedRoute,
    private navTo : Router
  ) { }
  
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.SColis.idFournisseur = Number(localStorage.getItem('idFournisseur'));
    this.SColis.PhoneClient = this.id;  
   
 this.colisService.postData(this.endpoint,this.SColis).subscribe(
   (res) =>{
      this.tableContent = res;
      if(this.tableContent.length>0){
        this.PhoneFound = true
      }
      //console.log(this.tableContent);    
   },

   (error) => console.log(error.message)
   ) ;
  
  }

  changeRating(Note,idColis,idOper,idFour){     
        
    if (confirm('Confirmez-Vous la note attribuée à l\'Operateur?')) {
      this.OperNotesData.idcolis = idColis;
      this.OperNotesData.idfour = idFour;
      this.OperNotesData.idoper = idOper;
      this.OperNotesData.note = Note; 
      //console.log('Note oper:',this.OperNotesData); 
      this.colisService.postData(this.MakeOperRatingEndpoint,this.OperNotesData).subscribe(
            (res) => {  
        },
         (error) => {
           //console.log(error);
         },
       );
       /* let currentUrl = this.navTo.url;
       this.navTo.navigateByUrl('/'+currentUrl, {skipLocationChange: true}).then(() => {
       this.navTo.navigate([currentUrl]); 
       console.log(currentUrl);
       }); */

       this.colisService.postData(this.endpoint,this.SColis).subscribe(
        (res) =>{
           this.tableContent = res;
           if(this.tableContent.length>0){
             this.PhoneFound = true
           }
           //console.log(this.tableContent);    
        },
     
        (error) => console.log(error.message)
        ) ;

         /* // this section is used to reload the data on same page and dynamique update of the UI
              this.colisService.getbyid(this.endpoint,this.id).subscribe(
                (res) => {
                  this.tableContent = res;
                  //console.log(this.tableContent);
                },
                (error) => {
                  //console.log(error);
                 // this.errorHandler.handleError(error);
                },
              
              ) */

    } else {
      //console.log('No need to rate Me!')
    } 

   } // end changerating

}
