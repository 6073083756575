export enum Apiendpickup {
    getAllpickupByFour = 'pickup/MobAppgetAllpickupByFour',
    getColisofPickup = 'colis/MobAppgetAllColisByPickup',
    getlistecolissurPeriode = 'colis/MobilegetlistecolissurPeriode',
    getColisFourEncours='colis/MobColisEncoursForAFourID',
    MobColisdumoisForAFourID = 'colis/MobColisdumoisForAFourID',
    MobAppSearchColisByClientPhone ='colis/MobAppSearchColisByClientPhone',
    newFourMsg = 'msg/NewMsgFour',
    FourNewPwd = 'four/FourNewPwd',
    MakeOperRating = 'colis/makeOperRating',
    GetOperRating = 'colis/GetOperRating'
}
