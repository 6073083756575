import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
 public DirectComm : boolean = true // false = est géré par un Apporteur Affaire
  idApporteurAffaire : string;

  constructor(
    private router : Router
  ) { }

  ngOnInit() {
    this.idApporteurAffaire = localStorage.getItem('ApporteuruserTypeId');
     if (this.idApporteurAffaire ==='10') {
      this.DirectComm = false; //  géré par Apporteur affaire
    } 
    /* if (this.idApporteurAffaire ==='0'|| this.idApporteurAffaire ==='undefined' ){
      this.DirectComm = true; // pas géré par Apporteur affaire
    }  */
  }


  logout() {
    localStorage.clear(); //  -Remove all data set into LS
    this.router.navigateByUrl('login');
  }

}
