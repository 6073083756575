import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CommissionFourComponent } from '@features/finance/commission-four/commission-four.component';
import { PointJourComponent } from '@features/finance/point-jour/point-jour.component';
import { PointMoisComponent } from '@features/finance/point-mois/point-mois.component';
import { ClientsFidelesComponent } from '@features/marketing/clients-fideles/clients-fideles.component';
import { DetailclientsBycommComponent } from '@features/marketing/detailclients-bycomm/detailclients-bycomm.component';
import { ListeclientsBycommuneComponent } from '@features/marketing/listeclients-bycommune/listeclients-bycommune.component';
import { ColisofPickupComponent } from '@features/pickup/colisof-pickup/colisof-pickup.component';
import { MespickupComponent } from '@features/pickup/mespickup/mespickup.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { MsgfourComponent } from '@pages/msgfour/msgfour.component';
import { NewpasswordComponent } from '@pages/newpassword/newpassword.component';
import { NoncontactsComponent } from '@pages/noncontacts/noncontacts.component';
import { PwdoublierComponent } from '@pages/pwdoublier/pwdoublier.component';
import { LoginComponent } from 'src/pages/login/login.component';
import { AuthGuard } from '@syscore/guards/auth.guard';
import { ChartsComponent } from '@pages/charts/charts.component';
import { ColisfourEncoursComponent } from '@features/pickup/colisfour-encours/colisfour-encours.component';
import { ColissearchboxComponent } from '@features/pickup/colissearchbox/colissearchbox.component';
import { ColissearchresultatComponent } from '@features/pickup/colissearchresultat/colissearchresultat.component';
import { ColisfourduMoisComponent } from '@features/pickup/colisfourdu-mois/colisfourdu-mois.component';
import { PointsurPeriodeComponent } from '@features/finance/pointsur-periode/pointsur-periode.component';
import { CalendarperiodeComponent } from '@features/finance/calendarperiode/calendarperiode.component';
import { DetailcashPeriodeComponent } from '@features/finance/detailcash-periode/detailcash-periode.component';
import { DetailmobilemoneyPeriodeComponent } from '@features/finance/detailmobilemoney-periode/detailmobilemoney-periode.component';
import { DetailcredlivPeriodeComponent } from '@features/finance/detailcredliv-periode/detailcredliv-periode.component';
import { DetailcredexpPeriodeComponent } from '@features/finance/detailcredexp-periode/detailcredexp-periode.component';
import { ListecolisPeriodeComponent } from '@features/pickup/listecolis-periode/listecolis-periode.component';
import { CalendarcolisPeriodeComponent } from '@features/pickup/calendarcolis-periode/calendarcolis-periode.component';
import { InfosmsComponent } from '@features/marketing/infosms/infosms.component';
import { MoncptesmsComponent } from '@features/marketing/moncptesms/moncptesms.component';
import { SmsdemerciComponent } from '@features/marketing/smsdemerci/smsdemerci.component';
import { SmspublicitaireComponent } from '@features/marketing/smspublicitaire/smspublicitaire.component';
//import { AppComponent } from './app.component';

const routes: Routes = [

  {
    path: '',
    component:LoginComponent,
    //redirectTo: '/folder/Inbox',
    pathMatch: 'full'
  },
  
 {
    path: 'login',
    component:LoginComponent,
    //redirectTo: '/folder/Inbox',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component:DashboardComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
   // pathMatch: 'full'
  },
  {
    path: 'charts',
    component:ChartsComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
   // pathMatch: 'full'
  },
  {
    path: 'mespickup',
    component:MespickupComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },
  {
    //path: 'colisEncours/:id',
    path: 'colisEncours',
    component:ColisfourEncoursComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },
  {
    path: 'colisDuMois',
    component:ColisfourduMoisComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'colisofpickup/:id',
    component:ColisofPickupComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },
   {
    path: 'calendarcolisSurPeriode',
    component:CalendarcolisPeriodeComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },
  {
    path: 'listecolisSurPeriode',
    component:ListecolisPeriodeComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },
  {
    path: 'searchColis',
    component:ColissearchboxComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },
  {
    path: 'searchColisResultat/:id',
    component:ColissearchresultatComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },

  {
    path: 'financeJour',
    component:PointJourComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },
  {
    path: 'financeMois',
    component:PointMoisComponent,
    canActivate: [AuthGuard],
  
  },
  {
    path: 'calendarPeriode',
    component:CalendarperiodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financesurPeriode',
    component:PointsurPeriodeComponent,
    canActivate: [AuthGuard],
  },
  // start detail finance période
  {
    path: 'financesurPeriode/detailcashPeriode',
    component:DetailcashPeriodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financesurPeriode/detailMMPeriode',
    component:DetailmobilemoneyPeriodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financesurPeriode/detailcredLivPeriode',
    component:DetailcredlivPeriodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financesurPeriode/detailExpperiode',
    component:DetailcredexpPeriodeComponent,
    canActivate: [AuthGuard],
  },

// end
  {
    path: 'calendarPeriode',
    component:CalendarperiodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'CommFour',
    component:CommissionFourComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },

  {
    path: 'ClientByComm',
    component:ListeclientsBycommuneComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },
  
  {
    path: 'DetailsClientByComm/:id',
    component:DetailclientsBycommComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },

  {
    path: 'ClientFidele',
    component:ClientsFidelesComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },

  {
    path: 'noscontacts',
    component:NoncontactsComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },

  {
    path: 'msgfour',
    component:MsgfourComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },
  {
    path: 'Fournewpwd',
    component:NewpasswordComponent,
    canActivate: [AuthGuard],
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },
  {
    path: 'pwdOublier',
    component:PwdoublierComponent,
    
    //redirectTo: '/folder/Inbox',
    //pathMatch: 'full'
  },
// Section SMS
{
  path: 'infosms',
  component:InfosmsComponent,
},
{
  path: 'moncptesms',
  component:MoncptesmsComponent,
},
{
  path: 'msgdemerci',
  component:SmsdemerciComponent,
},
{
  path: 'smspub',
  component:SmspublicitaireComponent,
},

 /*  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  } */ 

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
