import { Component, OnInit } from '@angular/core';
//import { Apibase } from '@shared/apibase';
import {FormBuilder, Validators} from '@angular/forms';
//import { Apiendpickup } from '@features/pickup/apiendpickup.enum';
import { Router } from '@angular/router';

interface SearchColis{
  //idFournisseur :number,
  PhoneClient : string,
}


@Component({
  selector: 'app-colissearchbox',
  templateUrl: './colissearchbox.component.html',
  styleUrls: ['./colissearchbox.component.scss'],
})
export class ColissearchboxComponent implements OnInit {

  //idFour! : number;
  //endpoint : Apiendpickup = Apiendpickup.MobAppSearchColisByClientPhone;
  SColis = {} as SearchColis;

  constructor(
    //private colis : Apibase,
    private fb:FormBuilder,
    private router : Router
  ) { }

  SearchBox = this.fb.group({
    PhoneClient :['',[Validators.required]],
   });
  
 
  ngOnInit() {
  
  }

  onSubmit(){
    //console.log(this.MsgForm.value);
    //this.SColis.idFournisseur = Number(localStorage.getItem('idFournisseur'));
    this.SColis.PhoneClient = this.SearchBox.get('PhoneClient').value;
    localStorage.setItem('SearchPhone', this.SColis.PhoneClient);
    //this.router.navigateByUrl('searchColisResultat')
    this.router.navigate(['searchColisResultat/'+this.SColis.PhoneClient]);
 
  /*   this.colis.postData(this.endpoint,this.SColis).subscribe(
      (response) =>{
     
        this.router.navigateByUrl('dashboard')
      },

      (error) => console.log(error)
      ) */;


  }


}
