import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { CustomHttpInterceptor } from './interceptors/http-interceptor';

@NgModule({
  declarations: [],
  imports: SyscoreModule.MODULE_LIST,
  exports: SyscoreModule.MODULE_LIST,

  
  providers: [
  {
    provide: HTTP_INTERCEPTORS, 
    useClass: AuthInterceptor, 
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomHttpInterceptor,
    multi: true
  }
]

  })
  export class SyscoreModule{

   static readonly MODULE_LIST = [
   CommonModule,
   HttpClientModule,      
   
  ];
   
  }

