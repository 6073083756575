import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import {FormBuilder, Validators} from '@angular/forms';
import { Apiendpickup } from '@features/pickup/apiendpickup.enum';
import { Router } from '@angular/router';

interface msg{
  idFournisseur :number,
  idCommuneFournisseur : number,
  NbreColis : number,
  MsgContent : string,
  
}

@Component({
  selector: 'app-msgfour',
  templateUrl: './msgfour.component.html',
  styleUrls: ['./msgfour.component.scss'],
})
export class MsgfourComponent implements OnInit {
  idFour! : number;
  idCommFour! : number;
  endpoint : Apiendpickup = Apiendpickup.newFourMsg;
  msgColis = {} as msg;

  constructor(
    private msg : Apibase,
    private fb:FormBuilder,
    private router : Router
  ) { }

  MsgForm = this.fb.group({
    nbreColis :['',[Validators.required]],
    msgEnlev : ['',[Validators.required]]
  });
  
 
  ngOnInit() {
  
  }

  onSubmit(){
    //console.log(this.MsgForm.value);
    this.msgColis.idFournisseur = Number(localStorage.getItem('idFournisseur'));
    this.msgColis.idCommuneFournisseur = Number(localStorage.getItem('idCommFour'));
    this.msgColis.NbreColis = Number(this.MsgForm.get('nbreColis').value);
    this.msgColis.MsgContent = this.MsgForm.get('msgEnlev').value;
   
    //console.log(this.msgColis);
    this.msg.postData(this.endpoint,this.msgColis).subscribe(
      (response) =>{
        alert('Merci de votre confiance - vos colis seront enlèvés');
        this.router.navigateByUrl('dashboard')
      },

      (error) => console.log(error));

  }

}
