import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import {FormBuilder, Validators} from '@angular/forms';
import { Apiendmarketing } from '../apiendmarketing.enum';
import { Router } from '@angular/router';
import { Alert } from 'selenium-webdriver';

interface smsmsg{
  idFournisseur :number,
  RadiobtnValue: any,
  MsgContent : string,
  NbrsSMSToSend : number
  
}

@Component({
  selector: 'app-smspublicitaire',
  templateUrl: './smspublicitaire.component.html',
  styleUrls: ['./smspublicitaire.component.scss'],
})

export class SmspublicitaireComponent implements OnInit {
  idFour! : number;
  endpointFourDataSMS : Apiendmarketing = Apiendmarketing.endpointFourDataSMS;
  endpointmsgpub : Apiendmarketing = Apiendmarketing.endpointmsgpub;
  msgSMS = {} as smsmsg;
  tableContent:any=[];
  OkToSend : boolean = false;
  tableContentDataFour:any=[];
  id!:number;

  constructor(
    private msg : Apibase,
    private clients : Apibase,
    private fb:FormBuilder,
    private router : Router
  ) { }

   MsgForm = this.fb.group({
    msgPub :['',[Validators.required]]
  }); 
 
 
  ngOnInit() {
    this.id =  Number(localStorage.getItem('idFournisseur'));

    this.clients.getbyid(this.endpointFourDataSMS,this.id).subscribe(
      (res) => {
        // Get relevant data for Four
        this.tableContentDataFour = res;
        var NbreFidel = this.tableContentDataFour[0]['ItemValue'];
        var NbreTotalClient = this.tableContentDataFour[1]['ItemValue'];
        var SoldeSMS = this.tableContentDataFour[2]['ItemValue'];

        // Local Storage for later retrieval
        localStorage.setItem('NbreFidel',NbreFidel);
        localStorage.setItem('NbreTotalClient',NbreTotalClient);
        localStorage.setItem('SoldeSMS',SoldeSMS);

        //console.log(this.tableContentDataFour);
      },
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      }
    )
  }

  checkValue(event){ // get le choix de la cible
    this.msgSMS.RadiobtnValue = Number(event.detail.value);
    //console.log(event.detail.value);
  }
  
  onSubmit(){   
    if (this.msgSMS.RadiobtnValue){ // Si cible choissie
      this.msgSMS.idFournisseur = Number(localStorage.getItem('idFournisseur'));
      this.msgSMS.MsgContent = this.MsgForm.get('msgPub').value;
      let msgtosend = this.MsgForm.get('msgPub').value;
      let solde =Number(localStorage.getItem('SoldeSMS'));
      let MsgNbreSMS =  Math.ceil((msgtosend.length + 20)  / 160);
      //console.log('Nbre Msg:',MsgNbreSMS);

      // Recup et traitement
      if (this.msgSMS.RadiobtnValue ===2){ //cible tous les clients
           //cal message length Vs SoldeSMS
           let ClientAll = Number(localStorage.getItem('NbreTotalClient'));
           let NbreSMSRequired  = ClientAll*MsgNbreSMS;
           this.msgSMS.NbrsSMSToSend = NbreSMSRequired;
           if (NbreSMSRequired > solde ){ // solde insuffisant
            alert('Solde SMS insuffisant. Contactez LMDC pour vous recharger en SMS (50F/SMS).');
           } else { // Solde Ok
            this.OkToSend = true;
           }

      } else if (this.msgSMS.RadiobtnValue ===3) { // cible client fidels
        //cal message length Vs SoldeSMS
        let ClientFi = Number(localStorage.getItem('NbreFidel'));
        let NbreSMSNeeded  = ClientFi*MsgNbreSMS;
        this.msgSMS.NbrsSMSToSend = NbreSMSNeeded;
        if (NbreSMSNeeded > solde ){ // solde insuffisant
          alert('Solde SMS insuffisant. Contactez LMDC pour vous recharger en SMS (50F/SMS).')
        } else { // Solde Ok
          this.OkToSend = true;
        }

      } else if (this.msgSMS.RadiobtnValue ===1){ //cible test
        let test = 1; // four lui meme
        let NbreSMSNeededtest  = test*MsgNbreSMS;
        this.msgSMS.NbrsSMSToSend = NbreSMSNeededtest;
        if (NbreSMSNeededtest > solde ){ // solde insuffisant
          alert('Solde SMS insuffisant. Contactez LMDC pour vous recharger en SMS (50F/SMS).')
        } else { // Solde Ok
          this.OkToSend = true;
        }
      }

      //console.log(this.msgSMS);
        } else {
      alert('Vous devez choissir la cible du message SMS!')
    }
    if(this.OkToSend === true) {
       console.log('message final:',this.msgSMS);
          this.msg.postData(this.endpointmsgpub,this.msgSMS).subscribe(
            (response) =>{
              alert('Merci - Votre Message SMS est encours d\'envoi');
              this.router.navigateByUrl('moncptesms')
            },
            (error) => console.log(error)
            );

        }
    } // end  if( this.OkToSend === true)
}
