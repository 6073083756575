import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Apiendfinance } from '@features/finance/apiendfinance.enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  id!: number;
  endpoint : Apiendfinance = Apiendfinance.getDashBoardItems;
  tableContent:any=[];

  constructor(
    private dash : Apibase
  ) { }

  ngOnInit() {
    this.id = Number(localStorage.getItem('idFournisseur'));

    this.dash.getbyid(this.endpoint,this.id).subscribe(
      (res) => {
        this.tableContent = res;
        //console.log(this.tableContent);
      },
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      },
    
    );
  }

}
