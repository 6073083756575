import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@syscore/service/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild  {

  constructor(private router: Router,
    private authservice:AuthService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean 
    | UrlTree {
      const token = localStorage.getItem('id_token');

      if (token) {

        if(this.authservice.isLoggedIn()) {
          return true;
          } else {
            this.router.navigateByUrl('login');
            return false;
        }
        }
        else {
        this.router.navigateByUrl('login');
        return false;
        }
  } //end 

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean 
    | UrlTree {
      const token = localStorage.getItem('id_token');

      if (token) {

        if(this.authservice.isLoggedIn()) {
          return true;
          } else {
            this.router.navigateByUrl('login');
            return false;
        }
        }
        else {
        this.router.navigateByUrl('login');
        return false;
        }
  } //end 
  
}
