import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Apiendmarketing } from '../apiendmarketing.enum';

@Component({
  selector: 'app-clients-fideles',
  templateUrl: './clients-fideles.component.html',
  styleUrls: ['./clients-fideles.component.scss'],
})
export class ClientsFidelesComponent implements OnInit {
  endpoint : Apiendmarketing = Apiendmarketing.getlisteClientFidele;
  tableContent:any=[];
  id!:number;

  constructor(
    private clients : Apibase,
  ) { }

  ngOnInit() {
    this.id =  Number(localStorage.getItem('idFournisseur'));
    this.clients.getbyid(this.endpoint,this.id).subscribe(
      (res) => {
        this.tableContent = res;
        //console.log(this.tableContent);
      },
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      },
    
    )

  }

}
