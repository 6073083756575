import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Apiendmarketing } from '../apiendmarketing.enum';

@Component({
  selector: 'app-smsdemerci',
  templateUrl: './smsdemerci.component.html',
  styleUrls: ['./smsdemerci.component.scss'],
})
export class SmsdemerciComponent implements OnInit {

  endpoint : Apiendmarketing = Apiendmarketing.getFourData;
  TestMsgdeThankYouFour: Apiendmarketing = Apiendmarketing.TestMsgdeThankYouFour;

  tableContent:any=[];
  id!:number;
  constructor(
    private clients : Apibase,
  ) { }

  ngOnInit() {
    this.id =  Number(localStorage.getItem('idFournisseur'));
    this.clients.getbyid(this.endpoint,this.id).subscribe(
      (res) => {
        this.tableContent = res;
        //console.log(this.tableContent);
      },
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      },
    
    )
  }

  SendTestSMS() {
    this.id =  Number(localStorage.getItem('idFournisseur'));
    this.clients.getbyid(this.TestMsgdeThankYouFour,this.id).subscribe(
      (res) => {
        this.tableContent = res;
       alert('Consultez votre téléphone - Message envoyé !')
      },
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      },
    
    );

    this.clients.getbyid(this.endpoint,this.id).subscribe(
      (res) => {
        this.tableContent = res;
        //console.log(this.tableContent);
      },
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      },
    
    )

  }

}
