import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Apiendmarketing } from '../apiendmarketing.enum';

@Component({
  selector: 'app-moncptesms',
  templateUrl: './moncptesms.component.html',
  styleUrls: ['./moncptesms.component.scss'],
})
export class MoncptesmsComponent implements OnInit {
  endpoint : Apiendmarketing = Apiendmarketing.getFourData;
  tableContent:any=[];
  id!:number;
  constructor(
    private clients : Apibase,
  ) { }

  ngOnInit() {
    this.id =  Number(localStorage.getItem('idFournisseur'));
    
    this.clients.getbyid(this.endpoint,this.id).subscribe(
      (res) => {
        this.tableContent = res;
        //console.log(this.tableContent);
      },
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      }
    )




  }

}
