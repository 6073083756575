import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Router } from '@angular/router';
import { Validators, FormBuilder} from '@angular/forms';
import { Apiendpickup } from '@features/pickup/apiendpickup.enum';

@Component({
  selector: 'app-newpassword',
  templateUrl: './newpassword.component.html',
  styleUrls: ['./newpassword.component.scss'],
})
export class NewpasswordComponent implements OnInit {
idFour! : number;
PinData:any = [];
item = {}; // data to be updated in DB
endpoint : Apiendpickup = Apiendpickup.FourNewPwd;
  constructor(
    private connex : Apibase,
    private router : Router,
    private fb:FormBuilder,
  ) { }

  NewpwdForm = this.fb.group({
    Newpwd : ['',[Validators.required,Validators.minLength(3), Validators.maxLength(50)]],
    ConfirmNewpwd: ['',[Validators.required, Validators.minLength(3), Validators.maxLength(50)]]
  });
  
  ngOnInit() {
    this.idFour= Number(localStorage.getItem('idFournisseur'));
  }

  onSubmit() {
    this.item = this.NewpwdForm.value;
    this.PinData= this.NewpwdForm.value;
     if(this.PinData['Newpwd'] !== this.PinData['ConfirmNewpwd']){
       alert('Confirmation incorrecte - Veuillez reprendre');
     }else{
       this.connex.updateItem(this.endpoint,this.idFour,this.item).subscribe(
         (response) =>{
           alert('Mot de passe changeé avec succès - Veuillez vous reconnecter'),
           this.router.navigateByUrl('login')
         },
         (error) => console.log(error)
       );
     }
   }

}
