import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Apiendpickup } from '../apiendpickup.enum';

interface NotesData {
  note: number,
  idcolis: number,
  idoper: number,
  idfour: number
}

@Component({
  selector: 'app-colisfour-encours',
  templateUrl: './colisfour-encours.component.html',
  styleUrls: ['./colisfour-encours.component.scss'],
})
export class ColisfourEncoursComponent implements OnInit {

  id! : number;
  OperNotesData : NotesData = { // permet d'initialiser
    note: 0,
    idcolis: 0,
    idoper: 0,
    idfour: 0
  };
  iconsArray = [1, 2, 3, 4, 5];
  endpoint : Apiendpickup = Apiendpickup.getColisFourEncours;
  MakeOperRatingEndpoint :Apiendpickup = Apiendpickup.MakeOperRating;
  
  tableContent:any=[];
    constructor(
      private colisService : Apibase,
     
    ) { }
  
    ngOnInit() {
      this.id = Number(localStorage.getItem('idFournisseur'));
      this.colisService.getbyid(this.endpoint,this.id).subscribe(
        (res) => {
          this.tableContent = res;
          console.log(this.tableContent);
        },
        (error) => {
          //console.log(error);
         // this.errorHandler.handleError(error);
        },
      
      )
    }
  
    changeRating(Note,idColis,idOper,idFour){     
        
      if (confirm('Confirmez-Vous la note attribuée à l\'Operateur?')) {
        this.OperNotesData.idcolis = idColis;
        this.OperNotesData.idfour = idFour;
        this.OperNotesData.idoper = idOper;
        this.OperNotesData.note = Note; 
        //console.log('Note oper:',this.OperNotesData); 
        this.colisService.postData(this.MakeOperRatingEndpoint,this.OperNotesData).subscribe(
              (res) => {  
          },
           (error) => {
             //console.log(error);
           },
         );
           // this section is used to reload the data on same page and dynamique update of the UI
                this.colisService.getbyid(this.endpoint,this.id).subscribe(
                  (res) => {
                    this.tableContent = res;
                    //console.log(this.tableContent);
                  },
                  (error) => {
                    //console.log(error);
                   // this.errorHandler.handleError(error);
                  },
                
                )
  
      } else {
        //console.log('No need to rate Me!')
      } 
  
     } // end changerating

}
