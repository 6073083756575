import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PointJourComponent } from './point-jour/point-jour.component';
import { PointMoisComponent } from './point-mois/point-mois.component';
import { CommissionFourComponent } from './commission-four/commission-four.component';
import { PointsurPeriodeComponent } from './pointsur-periode/pointsur-periode.component';
import { CalendarperiodeComponent } from './calendarperiode/calendarperiode.component';
import { DetailcashPeriodeComponent } from './detailcash-periode/detailcash-periode.component';
import { DetailcredlivPeriodeComponent } from './detailcredliv-periode/detailcredliv-periode.component';
import { DetailmobilemoneyPeriodeComponent } from './detailmobilemoney-periode/detailmobilemoney-periode.component';
import { DetailcredexpPeriodeComponent } from './detailcredexp-periode/detailcredexp-periode.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    PointJourComponent,
    PointMoisComponent,
    CommissionFourComponent,
    PointsurPeriodeComponent,
    CalendarperiodeComponent,
    DetailcashPeriodeComponent,
    DetailcredlivPeriodeComponent,
    DetailmobilemoneyPeriodeComponent,
    DetailcredexpPeriodeComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule
  ]
})
export class FinanceModule { }
