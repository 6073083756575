import { Component } from '@angular/core';
//import { Router } from '@angular/router';
import { SpinnerService } from '@syscore/service/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
/*   public appPages = [
    { title: 'Mes PickUps', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Finance', url: '/folder/Outbox', icon: 'paper-plane',
    subPages:[
      { title: 'Point Jour', url: '/folder/Inbox', icon: 'mail' },
      { title: 'Mes Comissions Jour', url: '/folder/Inbox', icon: 'mail' },
    ]
    },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ]; */
  //public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(
    public spinnerService: SpinnerService,
    //private router : Router
  ) {}

  /* logout() {
    localStorage.clear(); //  -Remove all data set into LS
    this.router.navigateByUrl('login');
  } */


}
