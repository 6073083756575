import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Apiendfinance } from '../apiendfinance.enum';

@Component({
  selector: 'app-commission-four',
  templateUrl: './commission-four.component.html',
  styleUrls: ['./commission-four.component.scss'],
})
export class CommissionFourComponent implements OnInit {
  id!: number;
  idApporteurAffaire : string;
  //commission :boolean=false; // Four a droit a commission directement ou pas
  tableContent:any=[];
  EmpFourtab :any=[];
  endpoint : Apiendfinance = Apiendfinance.getComFour;

  constructor(
    private CommFinance : Apibase,
    private httpClient: HttpClient) {
    }

  ngOnInit() { 
    this.id = Number(localStorage.getItem('idFournisseur'));
    //this.idApporteurAffaire = localStorage.getItem('ApporteuruserTypeId');
    // if (this.idApporteurAffaire ==='0'|| this.idApporteurAffaire ==='undefined' ){ // cette section n'est plus importante car 
      // l'affichage du menu commission est déjà géré via le Menu. Si géré par apporteur, Four ne verra meme pas le menu
     // this.commission = true; // pas géré par Apporteur affaire
    //} 
  this.CommFinance.getAll(this.endpoint).subscribe(
  (res) => {
    this.tableContent = res;
    //console.log(this.tableContent);
   
    // Ce code below me permet de recup un objet specfique dans un Array
    // en me basant sur une critère donné. - Ici tous les salaires viennent et je recupe 
    // uniquement pour l'operateur qui m'interesse via son idOperateurs
    let CommF = this.tableContent.find(emp => emp.idFournisseur === this.id)
             // critère ici le idFounisseur
  
   // console.log(CommF);
    this.EmpFourtab = [CommF]; 
    //console.log(this.EmpFourtab);
    },
    (error) => {
    //console.log(error);
   // this.errorHandler.handleError(error);
  },

);  
}


}
