import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apibase } from '@shared/apibase';
import { Apiendmarketing } from '../apiendmarketing.enum';

interface DataToSend {
  idCommune? : number,
  idFour?: number,
}


@Component({
  selector: 'app-detailclients-bycomm',
  templateUrl: './detailclients-bycomm.component.html',
  styleUrls: ['./detailclients-bycomm.component.scss'],
})
export class DetailclientsBycommComponent implements OnInit {
  Data ={} as  DataToSend;
  endpoint : Apiendmarketing = Apiendmarketing.getlisteClient;
  tableContent:any=[];

  constructor(
    private clients : Apibase,
    private route : ActivatedRoute
  ) { }

  ngOnInit() {
    this.Data.idCommune = Number(this.route.snapshot.paramMap.get('id'));
    this.Data.idFour = Number(localStorage.getItem('idFournisseur'));

    this.clients.postData(this.endpoint,this.Data).subscribe(
      (res) => {
        this.tableContent = res;
        //console.log(this.tableContent);
      },
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      },
    
    )
  
  }

}
