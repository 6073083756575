import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
//import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  //constructor(private authservice:AuthService) {}

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

const idToken = localStorage.getItem("id_token"); // id_token was set during login process

if (idToken) {
  
  const cloned = req.clone({
      headers: req.headers.set("Authorization",
     idToken)
  });
  //console.log(cloned);
  return next.handle(cloned);
 
  } else {
     return next.handle(req);
    }

}}
