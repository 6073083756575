import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apibase } from '@shared/apibase';
import { Apiendmarketing } from '../apiendmarketing.enum';

@Component({
  selector: 'app-listeclients-bycommune',
  templateUrl: './listeclients-bycommune.component.html',
  styleUrls: ['./listeclients-bycommune.component.scss'],
})
export class ListeclientsBycommuneComponent implements OnInit {
  tableContent:any=[];
  id!: number;
  endpoint : Apiendmarketing = Apiendmarketing.getClientGroupByCommune;


  constructor(
    private router : Router,
    private ListeClients : Apibase
  ) { }

  ngOnInit() {
    this.id = Number(localStorage.getItem('idFournisseur'));
    this.ListeClients.getbyid(this.endpoint,this.id).subscribe(
      (res) => {
        this.tableContent = res;
       //console.log(this.tableContent);
      },
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      },
    
    );
  }


  DetailsListeClient(code){
       this.router.navigate(['DetailsClientByComm/'+code]);
  }

}
