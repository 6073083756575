import { Component, OnInit } from '@angular/core';
import { Apibase } from '@shared/apibase';
import { Apiendfinance } from '../apiendfinance.enum';

@Component({
  selector: 'app-point-jour',
  templateUrl: './point-jour.component.html',
  styleUrls: ['./point-jour.component.scss'],
})
export class PointJourComponent implements OnInit {
  id!: number;
  endpoint : Apiendfinance = Apiendfinance.getpointJourFour;
  tableContent:any=[];

  constructor(
    private finance : Apibase
  ) { }

  ngOnInit() {
    this.id = Number(localStorage.getItem('idFournisseur'));
  
    //console.log(this.id)
    this.finance.getbyid(this.endpoint,this.id).subscribe(
      (res) => {
        this.tableContent = res;
        //console.log(this.tableContent);
      },
      (error) => {
        //console.log(error);
       // this.errorHandler.handleError(error);
      },
    
    );
  }

}
