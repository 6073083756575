import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class Apibase {
  baseUrl = environment.baseUrl
    constructor(
        private httpClient: HttpClient,
        //private baseUrl: string,
        //private endpoint: string, // Need to implement Enum for EndPoints.
       
      ) {}

      create(endpoint:string, item:any){ // Add new item to DB table
        return this.httpClient.post(`${this.baseUrl}/${endpoint}`, item);
      };

      postData(endpoint:string, item:any){ // use to get some data with general parameter,  not ID based - Finance section
        //Sometime we will need to construct the Item as an objet before transmitting to this API
        return this.httpClient.post(`${this.baseUrl}/${endpoint}`, item);
      };

     getAll(endpoint:string) { // Retrieve All items based on params
        return this.httpClient.get(`${this.baseUrl}/${endpoint}`) 
     };
    
    /*  getAll(params:any) { // Retrieve All items based on params
        return this.httpClient.get(`${this.baseUrl}/${this.endpoint}`,{params}) // CHECK FOR PARAMS
     }; */
    
        
     getbyid(endpoint:string,id:number) { // Get one ressource based on its id
     return this.httpClient.get(`${this.baseUrl}/${endpoint}/${id}`);
     };
    
    
    updateItem(endpoint:string,id:number,item:any){ // update a ressource based on its id
      return this.httpClient.put(`${this.baseUrl}/${endpoint}/${id}`, item)
     }
    
    DeleteItem(endpoint:string,id:number) {
      return this.httpClient.delete(`${this.baseUrl}/${endpoint}/${id}`);
    }
    
}
