import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { ListeclientsBycommuneComponent } from './listeclients-bycommune/listeclients-bycommune.component';
import { DetailclientsBycommComponent } from './detailclients-bycomm/detailclients-bycomm.component';
import { ClientsFidelesComponent } from './clients-fideles/clients-fideles.component';
import { SmspublicitaireComponent } from './smspublicitaire/smspublicitaire.component';

@NgModule({
  declarations: [
    ListeclientsBycommuneComponent,
    DetailclientsBycommComponent,
    ClientsFidelesComponent,
    SmspublicitaireComponent

  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule
  ]
})
export class MarketingModule { }
